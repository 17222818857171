import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { useState } from "react";
import { CButton } from "../utils/globalStyles";
import Layout from "../components/layout";
import { sendVerifyEmail, user } from "../utils/auth";
import VerifiedIcon from "@material-ui/icons/VerifiedUser";
import NotVerifiedIcon from "@mui/icons-material/GppMaybe";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import { NavigatedToState } from "src/types";

const useStyles = makeStyles({
  table: {
    minWidth: 200
  },
  tableContainer: {
    maxWidth: 680,
    width: "100%",
    display: "block",
    margin: "0 auto",
    borderRadius: 0
  }
});

type Props = NavigatedToState;
const Profile: React.FC<Props> = ({ location }) => {
  const classes = useStyles();
  const [email, setEmail] = useState(user.getEmail());
  const [displayName, setDisplayName] = useState(user.getDisplayName());
  const [verified, setVerified] = useState(user.getVerified());
  const [loggedIn, setLoggedIn] = useState(user.getLoggedIn());
  useEffect(() => {
    return user.addTrigger(() => {
      setEmail(user.getEmail());
      setDisplayName(user.getDisplayName());
      setVerified(user.getVerified());
      setLoggedIn(user.getLoggedIn());
      if (!user.getEmail()) navigate("/sign-up", { state: { navigatedTo: true } });
    });
  }, []);
  useEffect(() => {
    if (location?.state?.navigatedTo && !email)
      navigate("/sign-up", { state: { navigatedTo: true } });
  }, [loggedIn]);
  return (
    <Layout>
      <Container>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ fontSize: "1.1em" }}
                  component="th"
                  scope="row"
                  align="right"
                >
                  Display Name
                </TableCell>
                <TableCell style={{ fontSize: "1.1em" }} align="left">
                  {displayName}
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="right">
                  <CButton
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      navigate("/profile/edit", {
                        state: { autofocus: "displayName" }
                      })
                    }
                  >
                    CHANGE
                  </CButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ fontSize: "1.1em" }}
                  component="th"
                  scope="row"
                  align="right"
                >
                  Email
                </TableCell>
                <TableCell style={{ fontSize: "1.1em" }} align="left">
                  {email}
                </TableCell>
                <TableCell>
                  {verified ? (
                    <Tooltip title="verified">
                      <VerifiedIcon />
                    </Tooltip>
                  ) : loggedIn ? (
                    <Tooltip title="not verified">
                      <NotVerifiedIcon />
                    </Tooltip>
                  ) : null}
                </TableCell>
                <TableCell align="right">
                  <CButton
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      navigate("/profile/edit", {
                        state: { autofocus: "email" }
                      })
                    }
                  >
                    CHANGE
                  </CButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {!verified && loggedIn ? (
          <Alert
            style={{ maxWidth: 680, margin: "0 auto" }}
            variant="outlined"
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => sendVerifyEmail()}
              >
                RESEND VERIFICATION EMAIL
              </Button>
            }
          >
            Your email is not verified. (If you have verified it, try reloading)
          </Alert>
        ) : null}
        {Boolean(email) ? (
          <CButton
            color="primary"
            variant="contained"
            style={{ margin: "0 auto", display: "block" }}
            onClick={() => user.logout().then(navigate("/"))}
          >
            Logout
          </CButton>
        ) : null}
      </Container>
    </Layout>
  );
};

export default Profile;
